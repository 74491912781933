import React from 'react'
import {
  StyledButton, StyledModalContent,
  StyledText, Container,
  StyledHeader, StyledParagraph,
  StyledButtonText, StyledModal
} from "./EventWarningModal.styles";

import {usePrismic} from "../../context/PrismicContext";


export const EventWarningModal = ({open, onClose }) => {

  const {
    prismicData: {
      prismicNexioRedirect: {
        cancel,
        continue_text,
        modaltext,
        modalheader
      }
    }
  } = usePrismic()

  return (
    <StyledModal
      closeIcon
      open={open}
      centered={true}
      closeOnDimmerClick={true}
      closeOnEscape
      onClose={onClose}>
      <StyledModalContent>
        <StyledHeader>
          <StyledText>Thanks for attending Qsciences Welcome Home event!</StyledText>
        </StyledHeader>
        <StyledParagraph>
          ON-SITE or Online Will-Call orders placed during Welcome Home should be claimed no later than Saturday, September 2 @ 8pm MST
          <br />
          <br />
          Orders not claimed will be shipped to the address on file for $50 USD the following week.
        </StyledParagraph>
        <Container justify="flex-end">
          <StyledButton onClick={onClose}>
            <StyledButtonText>
              <strong>I Agree</strong>
            </StyledButtonText>
          </StyledButton>
        </Container>
      </StyledModalContent>
    </StyledModal>
  )
}