import { EnrollerTypes } from '../types/enrollmentTypes'
import { QService } from '../services/q-services'

function capitalizeFirstLetter(str: string) {
  return str?.charAt(0).toUpperCase() + str?.slice(1)
}

function handleDecodeUri(str: string) {
  const mapObj = {
    '%26': '&',
    '%3D': '=',
  }
  return str.replaceAll(/(%26)|(%3D)/g, function (matched) {
    return mapObj[matched]
  })
}

async function getAmbassador(slug: string) {
  return await QService.User.getAssociateBySlug({
    slug: slug,
  })
    .then(({ associateBySlug: { firstName, lastName, displayName } }) => {
      if (displayName) {
        return displayName
      }
      return `${firstName} ${lastName}`
    })
    .catch(e => console.log(e))
}

function handleCapitalize(name: string) {
  return name ? capitalizeFirstLetter(name) : ''
}

export async function getUrlParamsWithSlug(
  uri: string,
  slug: string
): Promise<EnrollerTypes> {
  const urlSearchParams = new URLSearchParams(handleDecodeUri(uri))
  const urlParams = Object.fromEntries(urlSearchParams.entries())

  let enrollData = {
    firstname: handleCapitalize(urlParams?.firstname),
    lastname: handleCapitalize(urlParams?.lastname),
    store: slug,
    type: urlParams?.type,
    ambassadorName: await getAmbassador(slug),
  }

  return enrollData
}

export const addPotentialEnrollment = async data =>
  QService.User.addPotentialEnrollment(data)
