import React, { useState, useEffect } from 'react'
import { Divider, Menu } from 'semantic-ui-react'
import { useLocation } from '@reach/router'
// Components
import { SocialLinks, MarketPicker } from '../'
import BecomeAmbButton from '../Buttons/BecomeAmbButton'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { useCartContext } from '../../context/CartContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { formatLocaleString, findMarketUrl } from '../../utils/i18n/countries'
// Styles
import {
  StyledLink,
  StyledAnchor,
  Container,
  Title,
  Wrapper,
  BecomeAmbContainer,
  FlagImage,
} from './Menu.styles'

const SidebarMenu = ({ toggle }) => {
  const [showMarketPicker, setShowMarketPicker] = useState(false)
  const [isActive, setIsActive] = useState('')
  const { qUser, handleUserLogout, userType, isEventSite, isVipSite } =
    useAuthContext()
  const { resetCartState, isPcOfferInCart, isAmbOfferInCart } = useCartContext()

  const {
    activeCountries,
    storeCountryCode,
    langAndCountry,
    prismicData: {
      prismicSideMenu: {
        about,
        autoship,
        cart,
        contact,
        chat,
        hello,
        home,
        order_history,
        profile,
        programs,
        science,
        shop,
        sign_in,
        sign_out,
        user,
        reports,
      },
    },
  } = usePrismic()

  const location = useLocation()
  useEffect(() => {
    if (!!location) {
      const path = location.pathname.split('/')[1]
      setIsActive(path)
    }
  }, [location])

  const handleClick = (e, { name }) => {
    setIsActive(name)
    toggle()
  }

  const handleLogout = () => {
    resetCartState()
    handleUserLogout()
    toggle()
  }

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  const localString = formatLocaleString(langAndCountry)

  const langCountryArray = langAndCountry?.split('-')
  const parsedCountryCode =
    langCountryArray[1].toLowerCase() || storeCountryCode

  const marketUrl = findMarketUrl(parsedCountryCode, activeCountries)
  return (
    <Wrapper>
      {showMarketPicker && (
        <MarketPicker
          open={showMarketPicker}
          onClose={() => setShowMarketPicker(false)}
        />
      )}
      <Menu.Menu style={{ marginTop: '12px' }}>
        {qUser ? (
          <Menu.Item
            disabled
            content={
              <Container data-qa="greeting" large>
                <Title>{`${hello[0].text}, ${
                  qUser?.displayName || user[0].text
                }`}</Title>
              </Container>
            }
          />
        ) : null}
        {!isEventSite ? (
          <Menu.Item
            name="market"
            content={
              <Container data-qa="menuMarketPicker">
                <FlagImage src={marketUrl} />
                <Title>{localString}</Title>
              </Container>
            }
            onClick={() => {
              toggle()
              setShowMarketPicker(true)
            }}
            active={isActive === 'market'}
          />
        ) : null}
        <Divider />
        <Menu.Item
          as={StyledLink}
          to="/"
          name=""
          content={
            <Container data-qa="menuHome">
              <Title>{`${home[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === ''}
        />
        <Menu.Item
          as={StyledLink}
          to="/cart"
          name="cart"
          content={
            <Container data-qa="menuCart">
              <Title>{`${cart[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'cart'}
        />
        <Menu.Item
          as={StyledLink}
          to="/products"
          name="products"
          content={
            <Container data-qa="menuShopAll">
              <Title>{`${shop[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'products'}
        />
        {showAutoShip ? (
          <Menu.Item
            as={StyledLink}
            to="/subscriptions"
            name="subscriptions"
            onClick={handleClick}
            active={isActive === 'subscriptions'}
            content={
              <Container data-qa="subscriptions">
                <Title>{autoship[0].text}</Title>
              </Container>
            }
          />
        ) : null}
        {qUser ? (
          <>
            <Menu.Item
              as={StyledLink}
              to="/order-history"
              name="order-history"
              onClick={handleClick}
              active={isActive === 'order-history'}
              content={
                <Container data-qa="menuHistory">
                  <Title>{`${order_history[0].text}`}</Title>
                </Container>
              }
            />
            <Menu.Item
              as={StyledLink}
              to="/profile"
              name="profile"
              content={
                <Container data-qa="menuProfile">
                  <Title>{`${profile[0].text}`}</Title>
                </Container>
              }
              onClick={handleClick}
              active={isActive === 'profile'}
            />
            {userType === 'AMBASSADOR' && (
              <Menu.Item
                as={StyledLink}
                to="/reports"
                name="reports"
                content={
                  <Container data-qa="menuReports">
                    <Title>{reports}</Title>
                  </Container>
                }
                onClick={handleClick}
                active={isActive === 'reports'}
              />
            )}
          </>
        ) : null}
        <Divider />
        <Menu.Item
          as={StyledLink}
          to="/about"
          name="about"
          content={
            <Container data-qa="menuAbout">
              <Title>{`${about[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'about'}
        />
        <Menu.Item
          as={StyledLink}
          to="/q-program"
          name="q-program"
          content={
            <Container data-qa="menuPrograms">
              <Title>{`${programs[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'q-program'}
        />
        <Menu.Item
          as={StyledLink}
          to="/science"
          name="science"
          content={
            <Container data-qa="menuScience">
              <Title>{`${science[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'science'}
        />
        <Menu.Item
          as={StyledLink}
          to="/contact-us"
          name="contact-us"
          content={
            <Container data-qa="menuContact">
              <Title>{`${contact[0].text}`}</Title>
            </Container>
          }
          onClick={handleClick}
          active={isActive === 'contact-us'}
        />
        {qUser ? (
          <Menu.Item
            as={StyledAnchor}
            href="https://info.qsciences.com/chat"
            target="_blank"
            to="https://info.qsciences.com/chat"
            name="chat-with-support"
            content={
              <Container data-qa="menuChat">
                <Title>{chat}</Title>
              </Container>
            }
            onClick={handleClick}
            active={isActive === 'chat-with-support'}
          />
        ) : null}
        <Divider />
        {qUser ? (
          <>
            <Menu.Item
              as={StyledLink}
              to="/"
              name="logout"
              onClick={handleLogout}
              content={
                <Container data-qa="menuSignOut">
                  <Title>{`${sign_out[0].text}`}</Title>
                </Container>
              }
            />
          </>
        ) : (
          <Menu.Item
            as={StyledLink}
            to="/login"
            name="login"
            content={
              <Container data-qa="menuSignIn" large>
                <Title>{`${sign_in[0].text}`}</Title>
              </Container>
            }
            onClick={handleClick}
            active={isActive === 'login'}
          />
        )}
      </Menu.Menu>
      {qUser && userType !== 'AMBASSADOR' && !isAmbOfferInCart ? (
        <BecomeAmbContainer>
          <BecomeAmbButton $insidemenu />
        </BecomeAmbContainer>
      ) : null}
      <SocialLinks />
    </Wrapper>
  )
}

export default SidebarMenu
