import React, { useState, useEffect, useContext } from 'react'
import * as Sentry from '@sentry/react'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  deleteUser,
  getAuth,
} from 'firebase/auth'
// Contexts
import { usePrismic } from './PrismicContext'
// Services
import { auth } from '../services/firebase'
import { QService } from '../services/q-services'
import { Magento, MagentoAutoship } from '../services/magento'
// Types
import {
  AuthContextTypes,
  FirebaseLogin,
  InitAuthState,
  InitUserState,
  QUser,
} from '../types/contexts/AuthContextTypes'
import { CreditCard } from '../types/PaymentsTypes'
// Utils
import {
  getMagentoUser,
  getUplineUser,
  loginWithMagento,
} from '../utils/loginHelpers'
import { getCookie } from '../utils/cookieHelpers'
import { initialAuthState, initialUserState } from './initialStates'

const MDEFAULTCARD = 'mdefaultcard'

const AuthContext = React.createContext<Partial<AuthContextTypes>>({})

export const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
  const [
    {
      isAuthenticated,
      isAuthLoading,
      isEventSite,
      authToggle,
      isVerified,
      isVipSite,
      foundEmailButNotVerified,
    },
    setAuthState,
  ] = useState<InitAuthState>(initialAuthState)

  const [
    {
      cardsOnFile,
      isEnrollmentComplete,
      isReferral,
      magentoUser,
      qUser,
      qUserUpline,
      referralData,
      shouldAddPcFee,
      userType,
      expiredSubscription,
    },
    setUserState,
  ] = useState<Partial<InitUserState>>(initialUserState)

  const [showRenewalModalForAmb, setShowRenewalModalForAmb] = useState(false)
  const [qLoginIssue, setQLoginIssue] = useState('')
  const cookieInitState =
    getCookie('user-cookie-consent') === 'true' ? false : true
  const [isCookieModalOpen, setIsCookieModalOpen] = useState(cookieInitState)

  const providers = {
    apple: new OAuthProvider('apple'),
    facebook: new FacebookAuthProvider(),
    google: new GoogleAuthProvider(),
  }

  const handleFirebaseLogin = async ({
    email,
    password,
    isSignUp,
    handleError,
  }: FirebaseLogin) => {
    setAuthState(prev => ({ ...prev, isAuthLoading: true }))

    if (isSignUp) {
      return await createUserWithEmailAndPassword(auth, email, password).catch(
        ({ message }) => {
          handleError(message)
          setAuthState(prev => ({ ...prev, isAuthLoading: false }))
        }
      )
    }

    return await signInWithEmailAndPassword(auth, email, password).catch(
      async error => {
        if (error.code == 'auth/network-request-failed') {
          Sentry.captureMessage(
            `User ${email} tried to login but got a network error`
          )
        }
        if (error.message.includes('(auth/user-not-found)')) {
          return await createUserWithEmailAndPassword(
            auth,
            email,
            password
          ).catch(({ message }) => {
            handleError(message)
            setAuthState(prev => ({ ...prev, isAuthLoading: false }))
          })
        }
        handleError(error.message)
        setAuthState(prev => ({ ...prev, isAuthLoading: false }))
      }
    )
  }

  const handleUserLogout = async () => {
    // localStorage.clear()
    localStorage.removeItem('guestCartId')
    localStorage.removeItem('autoShipState')
    localStorage.removeItem('mdefaultcard')
    localStorage.removeItem('enrollmentForm')
    localStorage.removeItem('isNewSignUp')
    Magento.User.unSetToken()
    MagentoAutoship.User.unSetToken()
    QService.unsetToken()
    await signOut(auth).then(() => {
      setUserState({ ...initialUserState })
      setAuthState({ ...initialAuthState, authToggle: !authToggle })
    })
  }

  const resetPassword = (email: string): Promise<void> =>
    sendPasswordResetEmail(auth, email)

  const setIsVerified = () =>
    setAuthState(prev => ({
      ...prev,
      isVerified: true,
      authToggle: !authToggle,
    }))

  const setFirebaseToken = () => {
    const user = auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    QService.setToken(token)
    return token
  }

  const getCurrentFirebaseUserEmail = () => {
    const user = auth?.currentUser?.toJSON()
    return user?.email
  }

  const deleteCurrentFirebaseUser = () => {
    const auth = getAuth()
    const user = auth.currentUser
    deleteUser(user).catch(error => {
      console.log('error in delete user:', error)
    })
    handleUserLogout()
  }

  const handleRefreshSite = () => {
    setAuthState(prev => ({
      ...prev,
      authToggle: !prev.authToggle,
    }))
  }

  const updateMagentoUser = async () => {
    let magentoUser = await getMagentoUser()
    setUserState(prev => ({ ...prev, magentoUser }))
  }

  const updateQUser = async (data: Partial<QUser>) => {
    await QService.User.updateAssociate(data).then(updatedUserData => {
      const associateSlug = updatedUserData?.associateSlugs[0]?.slug
      return setUserState(prev => ({
        ...prev,
        qUser: { ...updatedUserData, associateSlug },
        isReferral: false,
      }))
    })
  }

  const updateQUserFromAmbEnrollmentForm = async (data: Partial<QUser>) =>
    await QService.User.updateAssociateInAmbassadorEnrollment(data).then(
      updatedUserData =>
        setUserState(prev => ({
          ...prev,
          isEnrollmentComplete: true,
          qUser: updatedUserData,
        }))
    )

  const {
    storeCountryCode,
    langAndCountry,
    prismicData: { prismicGeneral, prismicCartPage, prismicErrorData },
  } = usePrismic()

  const localeArray = langAndCountry.split('-')
  const parsedCountry = localeArray[1]

  const handleAddNewUser = async (userInfo: {
    firstName: string
    lastName: string
    enrollerLegacyAssociateId: number
    countryCode: string
    languageCode: string
  }) => {
    const displayName = `${userInfo.firstName} ${userInfo.lastName}`
    let newQUserData = {
      country: userInfo?.countryCode.toUpperCase() || parsedCountry || 'US',
      displayName,
      firstName: userInfo.firstName,
      languageCode: userInfo?.languageCode || 'en',
      lastName: userInfo.lastName,
      enrollerSlug: referralData?.store,
      enrollerLegacyAssociateId: userInfo?.enrollerLegacyAssociateId,
    }

    await QService.User.addNewQUser(newQUserData).then(() =>
      setAuthState(prev => ({
        ...prev,
        authToggle: !authToggle,
      }))
    )
  }

  const getExistingEnrollment = () => {
    const enrollData = localStorage.getItem('enrollerData')
    if (!enrollData) return

    const enrollerData = JSON.parse(enrollData)
    handleSetUserState({
      isReferral: true,
      referralData: enrollerData,
      shouldAddPcFee: enrollerData.type === 'pc',
    })
  }

  const manageReferral = {
    isEnrollmentComplete,
    isReferral,
    referralData,
  }

  const addCardToFile = async (card: CreditCard, saveToFile: boolean) => {
    updateSelectedCard(card)
    if (saveToFile)
      await QService.Payments.saveCardData(card)
        .then(() => toast.success(prismicGeneral.msg_new_card_added))
        .then(
          async () =>
            await QService.Payments.getCardsOnFile(qUser.associateId).then(
              cards => setUserState(prev => ({ ...prev, cardsOnFile: cards }))
            )
        )
  }

  const getSelectedCard = () => {
    try {
      const defaultCard =
        typeof window !== 'undefined' && localStorage.getItem(MDEFAULTCARD)
      const selectedCard = defaultCard
        ? JSON.parse(defaultCard)
        : cardsOnFile[0] || {}
      if (
        selectedCard?.creditCardType?.toLowerCase() === 'americanexpress' &&
        storeCountryCode === 'ca'
      )
        return {}
      return selectedCard
    } catch (error) {
      return {}
    }
  }

  const updateSelectedCard = (selectedCard: CreditCard) => {
    localStorage.setItem(MDEFAULTCARD, JSON.stringify(selectedCard))
  }

  const deleteCreditCard = async (cardGuid, associatePaymentInformationId) => {
    await QService.Payments.deletePaymentMethod({
      associatePaymentInformationId,
    })
      .then(response => {
        if (!response.isSuccess) {
          const errorMessage = response?.msg?.includes(
            'an AutoShip is using AssociatePaymentInformationId'
          )
            ? prismicCartPage.failed_to_remove_payment_method
            : prismicCartPage.failure_to_remove_payment_method
          return toast.error(errorMessage)
        } else {
          // remove from localStorage if it is saved
          toast.success('Card Removed')
          const cardInLocalStorage = localStorage.getItem(MDEFAULTCARD)
          const creditCardGuid =
            cardInLocalStorage &&
            JSON?.parse(cardInLocalStorage)?.creditCardGuid
          if (cardGuid === creditCardGuid) {
            console.log('there is a match')
            localStorage.removeItem('mdefaultcard')
          }
        }
      })
      .then(
        async () =>
          await QService.Payments.getCardsOnFile(qUser.associateId).then(
            cards =>
              setUserState(prev => ({
                ...prev,
                cardsOnFile: removeDuplicates(cards),
              }))
          )
      )
  }

  // THIS IS A TEMP FIX FOR THE DUPLICATE CC BUG
  const removeDuplicates = (cards: CreditCard[]) => {
    function uniqByKeepLast(a, key) {
      return [...new Map(a.map(x => [key(x), x])).values()]
    }
    return uniqByKeepLast(cards, card => card.creditCardGuid)
  }

  const refreshAuthState = async () => {
    const firebaseToken = setFirebaseToken()
    await QService.Auth.loginAssociate()
    await loginWithMagento({ ...qUser, firebaseToken })
  }

  const handleSetUserState = (userState: Partial<InitUserState>) =>
    setUserState(prev => ({ ...prev, ...userState }))

  const handleSetAuthState = (authState: Partial<InitAuthState>) =>
    setAuthState(prev => ({ ...prev, ...authState }))

  useEffect(() => {
    const persistEnrollerData = localStorage.getItem('persistEnrollerData')
    if (isAuthLoading) return
    if (qUser && persistEnrollerData !== 'true') {
      // REMOVE EXISTING ENROLLER DATA ONCE USER HAS CREATED ACCOUNT and STARTED AMBASSADOR ENROLLMENT
      localStorage.removeItem('enrollerData')
    } else {
      getExistingEnrollment()
    }
  }, [isAuthLoading, qUser])

  useEffect(() => {
    setAuthState(prev => ({ ...prev, isAuthLoading: true }))
    getRedirectResult(auth).catch(err => {
      if (err?.code === 'auth/web-storage-unsupported') {
        toast.error(prismicGeneral.msg_allow_third_party_cookies, {
          duration: 5000,
        })
      }
    })
    return onAuthStateChanged(auth, async user => {
      if (user) {
        // SET FIREBASE TOKEN IN AUTH HEADER FOR ALL SUBSEQUENT QSERVICE CALLS
        setQLoginIssue('')
        const firebaseToken = setFirebaseToken()

        // USING FIREBASE TOKEN, ATTEMPT TO LOGIN TO QS
        let {
          // message,
          success,
          loginResults,
          associate: qUser,
          expiredSubscription,
        } = await QService.Auth.loginAssociate()

        if (success) {
          // LOG USER IN TO MAGENTO, GET/SET TOKEN, RETURN USER DATA
          setQLoginIssue('')
          let magentoUser = await loginWithMagento({
            ...qUser,
            firebaseToken,
          })
            .then(({ magentoUser }) => magentoUser)
            .catch(err => {
              console.log(err)
            })

          if (!magentoUser) {
            setAuthState(prev => ({
              ...prev,
              isAuthLoading: false,
            }))
            toast.error(prismicGeneral.error_logging_in_to_magento)
            return
          }
          await QService.User.setCustomerGroup(qUser.associateType)

          let cardsOnFile = await QService.Payments.getCardsOnFile(
            qUser.associateId
          )

          // ADD UPLINE USER'S DATA TO LOGGED IN USER BEFORE SETTING STATE
          let { uplineUserData, associateSlug } = await getUplineUser()

          handleSetUserState({
            magentoUser,
            isEnrollmentComplete: !!(
              qUser?.employerIdentificationNumberToken ||
              qUser?.socialSecurityNumberToken
            ),
            userType: qUser?.associateType,
            qUser: { ...qUser, associateSlug },
            qUserUpline: uplineUserData,
            cardsOnFile: removeDuplicates(cardsOnFile),
            expiredSubscription,
          })

          setAuthState(prev => ({
            ...prev,
            isAuthLoading: false,
            isVerified: true,
            isAuthenticated: true,
          }))
        } else {
          // ! Handle other login results here...
          if (loginResults === 'NO_LOGIN') {
            setAuthState(prev => ({
              ...prev,
              isAuthLoading: false,
              isVerified: false,
              isAuthenticated: true,
            }))
            navigate('/login')
          } else if (loginResults === 'EMAIL_FOUND') {
            setAuthState(prev => ({
              ...prev,
              isAuthLoading: false,
              isVerified: false,
              isAuthenticated: true,
              foundEmailButNotVerified: true,
            }))
            handleSetUserState({ qUser })
            navigate('/login')
          } else if (loginResults === 'AMBASSADOR_ARQ_YEARLY_FAILED') {
            setQLoginIssue(
              `${prismicErrorData.issue_arq_annual} ${prismicErrorData.contact_support}`
            )
            await signOut(auth)
          } else if (loginResults === 'AMBASSADOR_ARQ_MONTHLY_FAILED') {
            setQLoginIssue(
              `${prismicErrorData.issue_arq_annual} ${prismicErrorData.contact_support}`
            )
            await signOut(auth)
          } else {
            setQLoginIssue(prismicErrorData.issue_with_account)
            await signOut(auth)
          }
          setAuthState(prev => ({
            ...prev,
            isAuthLoading: false,
          }))
        }
      } else {
        setAuthState(prev => ({
          ...prev,
          isAuthLoading: false,
        }))
      }
    })
  }, [authToggle])

  return (
    <AuthContext.Provider
      value={{
        authToggle,
        cardsOnFile,
        expiredSubscription,
        isAuthenticated,
        isAuthLoading,
        isCookieModalOpen,
        isEventSite,
        isVerified,
        isVipSite,
        foundEmailButNotVerified,
        magentoUser,
        manageReferral,
        qUser,
        qUserUpline,
        shouldAddPcFee,
        showRenewalModalForAmb,
        userType,
        addCardToFile,
        deleteCreditCard,
        deleteCurrentFirebaseUser,
        getCurrentFirebaseUserEmail,
        getSelectedCard,
        handleAddNewUser,
        handleFirebaseLogin,
        handleRefreshSite,
        handleSetAuthState,
        handleSetUserState,
        handleUserLogout,
        refreshAuthState,
        resetPassword,
        setIsVerified,
        setIsCookieModalOpen,
        setShowRenewalModalForAmb,
        updateMagentoUser,
        updateQUser,
        updateQUserFromAmbEnrollmentForm,
        updateSelectedCard,
        setFirebaseToken,
        qLoginIssue,
        setQLoginIssue,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

// -------------------- EXAMPLE SHARED URLS --------------------
// http://localhost:8000/store/innovation1?type=pc&firstname=Scotty&lastname=Skittles
// http://localhost:8000/store/innovation1?type=retail&firstname=Scotty&lastname=Skittles
// http://localhost:8000/store/innovation1?type=ambassador&firstname=Scotty&lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=pc&firstname=Scotty&lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=retail&firstname=Scotty&lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=ambassador&firstname=Scotty&lastname=Skittles

// -------------------- WITH URI ENCODING --------------------
// http://localhost:8000/store/innovation1?type=pc%26firstname=Scotty%26lastname=Skittles
// http://localhost:8000/store/innovation1?type=retail%26firstname=Scotty%26lastname=Skittles
// http://localhost:8000/store/innovation1?type=ambassador%26firstname=Scotty%26lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=pc%26firstname=Scotty%26lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=retail%26firstname=Scotty%26lastname=Skittles
// http://192.168.1.144:8000/store/innovation1?type=ambassador%26firstname=Scotty%26lastname=Skittles

// -------- NOTE - type of ambassador sends the user to /login route -------------
