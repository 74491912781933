import {Modal, Header, Button} from 'semantic-ui-react'
import styled, { css } from 'styled-components'

export const StyledModal = styled(Modal)`
    border-radius: 5px;
    border: 0.5px solid rgba(0,0,0, 1);
    box-shadow: 0px 2px 4px rgba(0,0,0, 0.5);
    opacity: 1;
    background-color: rgba(255,255,255, 1);
    width: 80%;
    max-width: 300px;
`

interface StyledTextProps {
  align: string
}

export const StyledText = styled.span`
  opacity: 1;
  color: rgba(0,0,0,1);
  font-family: "Arial-BoldMT";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  display: block;
  line-height: 18px;

`

export const StyledHeader = styled(Header)`
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  border-bottom: 0 !important;

  @media ${({ theme }) => theme.device.sm} {
    font-size: 22px !important;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.align || 'center'};
  width: 100%;
  max-width: ${props => props.maxWidth || 'none'};
  padding-top: 1em;
  align-self: center;
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  color: ${props => props.theme.colors.metallicSeaweed};
  :hover {
    cursor: pointer;
  }
`

export const StyledModalContent = styled(Modal.Content)`
  display: flex;
  padding: 10px;
  justify-content: center;
`

export const StyledButtonText = styled.span`
  typeface: Arial
  weight: 900
  size: 14
  style: normal
  character: 0
  color: #007985
  horizontal: Left
  vertical: Top
`

export const StyledParagraph = styled.span`
  opacity: 1;
  color: rgba(0,0,0,1);
  font-family: "ArialMT";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  `