import {
  ADD_ASSOCIATE,
  GET_ASSOCIATE_BY_SLUG,
  GET_USER_PROFILE,
  GET_USER_TREE_NODE,
  SET_CUSTOMER_GROUP,
  UPDATE_ASSOCIATE,
  IS_SLUG_AVAILABLE,
  GET_TREE_NODE_LITE,
  ADD_POTENTIAL_ENROLLMENT,
  COMMUNICATION_PREFERENCES,
} from '../graphql/user'

export const User = client => ({
  addNewQUser: async data =>
    await client
      .request(ADD_ASSOCIATE, data)
      .then(({ addAssociateV2 }) => addAssociateV2.associateId)
      .catch(e => console.log(e)),
  communicationPreferences: async newEnrollEmail =>
    await client.request(COMMUNICATION_PREFERENCES, newEnrollEmail)
      .catch(e => console.log(e)),  
  getUserTreeNode: async legacyId =>
    await client
      .request(GET_USER_TREE_NODE, legacyId)
      .catch(e => console.log(e)),
  getUserTreeNodeLite: async () =>
    await client.request(GET_TREE_NODE_LITE).catch(e => console.log(e)),
  getUserProfile: async associateId => {
    const { associates } = await client.request(GET_USER_PROFILE, associateId)
    return associates[0].displayName
  },
  getAssociateBySlug: async slug =>
    await client.request(GET_ASSOCIATE_BY_SLUG, slug),
  isSlugAvailable: async slug => {
    const { isSlugAvailable } = await client
      .request(IS_SLUG_AVAILABLE, slug)
      .catch(e => console.log(e))
    return isSlugAvailable?.isAvailable
  },
  setCustomerGroup: async (
    customerGroup: 'RETAIL' | 'PREFERRED' | 'AMBASSADOR',
    magentoCustomerId?: number
  ) =>
    await client.request(SET_CUSTOMER_GROUP, {
      customerGroup,
      magentoCustomerId,
    }),
  updateAssociate: async data =>
    await client
      .request(UPDATE_ASSOCIATE, data)
      .then(({ updateAssociateV2 }) => updateAssociateV2)
      .catch(e => console.log(e)),
  updateAssociateInAmbassadorEnrollment: async data =>
    await client
      .request(UPDATE_ASSOCIATE, data)
      .then(({ updateAssociateV2 }) => updateAssociateV2)
      .catch(e => console.log(e)),
  addPotentialEnrollment: async data =>
    await client
      .request(ADD_POTENTIAL_ENROLLMENT, data)
      .catch(err => console.log('err in addPotentialEnrollment:', err)),
})
